import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]

  connect() {
    // Fields are hidden by default
  }

  toggle(event) {
    const isChecked = event.target.checked

    this.fieldTargets.forEach(field => {
      if (isChecked) {
        field.classList.remove('d-none')
      } else {
        field.classList.add('d-none')
      }
    })
  }
}