<template>
  <div class="d-flex flex-column px-2">
    <div class="d-flex">
      <img class="img-thumbnail cursor-pointer avatar-big" :src="artist.profile_picture_url" @click="$emit('click')" />

      <div class="d-flex px-2 flex-column justify-content-center">
        <p class="mb-1 cursor-pointer" @click="$emit('click')">
          <strong class="me-1">{{ artist.nickname }}</strong>
          <mark v-if="artist.city || artist.age">
          (<span v-if="artist.age">{{ artist.age }} yrs</span>
            <span v-if="artist.city && artist.age"> | </span>
            <span v-if="artist.city">{{ artist.city }}</span>)
          </mark>
        </p>
        <ul v-if="artist.skills" class="list-unstyled d-flex flex-wrap gap-1 my-1">
          <li v-for="skill in artist.skills" :key="skill" class="badge text-bg-light">{{ skill }}</li>
        </ul>
        <p v-if="artist.notes" class="small p-1">
          {{ artist.notes }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["artist"],
};
</script>
